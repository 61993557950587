import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title=""
        description=""
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 pt-10 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/Thank You/1.0 hero.jpg"
                loading="eager"
                width={560}
              />
            </div>
            <div>
              <h1 className="heading-two">Thanks For Submitting!</h1>
              <p>
                We'll be getting in touch with you shortly. In the meantime, if
                you'd like to get started on your application, you can apply
                now. Our online application is safe, secure, and easy to fill
                out.
              </p>
              <ButtonSolid href="" text="Apply Now" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-global.jpg" }
    ) {
      publicURL
    }
  }
`;
export default Page;
